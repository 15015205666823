import queryString from 'query-string';

import { createHttpClient } from '~/utils/http-client';
import { getPreviewToken, isPreviewMode } from '~/sanity/preview';

const API_VERSION = 'v2021-05-25';

const isProdLike = ['production', 'stage'].includes(window.env.DEPLOY_ENV);

export const config = {
  projectId: 'afv8yn22',
  useCdn: isProdLike && !isPreviewMode,
  dataset: isProdLike ? 'production' : 'develop',
};

const http = createHttpClient({
  baseUrl: `https://${config.projectId}.api${
    config.useCdn ? 'cdn' : ''
  }.sanity.io/${API_VERSION}/data/query/${config.dataset}`,
  requestTransformers: [
    async (request) => {
      if (!isPreviewMode) {
        return request;
      }

      return {
        ...request,
        headers: {
          Authorization: `Bearer ${getPreviewToken()}`,
          ...request.headers,
        },
      };
    },
  ],
});

export const sanity = async ({ query, variables = {} }) => {
  // variables should start with "$" and values wrapped in quotes
  const formattedVariables = Object.keys(variables).reduce((obj, key) => {
    obj[`$${key}`] = `"${variables[key]}"`;
    return obj;
  }, {});

  const params = queryString.stringify({
    query,
    ...formattedVariables,
  });

  const res = await http.get(`?${params}`);

  return res.result;
};
