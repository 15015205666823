import React from 'react';
import { animated, useTransition, config } from '@react-spring/web';
import cx from 'classnames';

import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';

import styles from '~/components/messages/flash-message.module.scss';

// TODO: investigate how to make these more accessible

const FlashMessages = () => {
  const { messages, hideFlashMessage } = useFlashMessage();

  const transitions = useTransition(messages, {
    from: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
    },
    config: config.stiff,
  });

  return (
    <div className={styles.container}>
      {transitions((style, message) => (
        <animated.div
          className={cx(styles.message, {
            [styles.error]: message.variant === 'error',
            [styles.info]: message.variant === 'info',
            [styles.success]: message.variant === 'success',
          })}
          style={style}
        >
          {message.variant === 'error' && <ErrorIcon />}
          {message.variant === 'info' && <InfoIcon />}
          {message.variant === 'success' && <SuccessIcon />}

          <div>
            <div className={styles.messageHeading}>{message.variant}</div>
            {message.text}
          </div>

          <button
            className={styles.closeButton}
            type="button"
            aria-label="dismiss message"
            onClick={() => hideFlashMessage(message.text)}
          >
            <svg
              viewBox="0 0 55 55"
              xmlns="http://www.w3.org/2000/svg"
              stroke="var(--dark-color)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="var(--light-color)"
            >
              <path d="M 19 19 L 35 35" />
              <path d="M 35 19 L 19 35" />
            </svg>
          </button>
        </animated.div>
      ))}
    </div>
  );
};

export default FlashMessages;

export function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={styles.icon}
      aria-hidden="true"
    >
      <circle cx="32" cy="32" r="32" fill="var(--error-color)" />
      <path
        d="M28.85 40.16C29.7113 39.2987 30.7627 38.868 32.004 38.868C33.2453 38.868 34.2967 39.2987 35.158 40.16C36.0193 41.0213 36.45 42.0727 36.45 43.314C36.45 44.53 36.0193 45.5813 35.158 46.468C34.2967 47.3293 33.2453 47.76 32.004 47.76C30.7627 47.76 29.7113 47.3293 28.85 46.468C27.9887 45.5813 27.558 44.53 27.558 43.314C27.558 42.0727 27.9887 41.0213 28.85 40.16ZM28.432 37.272V18.348H35.614V37.272H28.432Z"
        fill="white"
      />
    </svg>
  );
}

function InfoIcon() {
  return (
    <svg
      className={styles.icon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      aria-hidden="true"
    >
      <path
        fill="var(--info-color)"
        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
      />
      <path
        fill="var(--light-color)"
        d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
      />
    </svg>
  );
}

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={styles.icon}
      aria-hidden="true"
    >
      <path
        fill="var(--secondary-color)"
        d="M504.1 256C504.1 119 393 7.9 256 7.9S7.9 119 7.9 256 119 504.1 256 504.1 504.1 393 504.1 256z"
      />
      <path
        fill="var(--light-color)"
        d="M392.6 172.9c-5.8-15.1-17.7-12.7-30.6-10.1-7.7 1.6-42 11.6-96.1 68.8-22.5 23.7-37.3 42.6-47.1 57-6-7.3-12.8-15.2-20-22.3-22.1-22.1-46.8-37.3-47.8-37.9-10.3-6.3-23.8-3.1-30.2 7.3-6.3 10.3-3.1 23.8 7.2 30.2.2.1 21.4 13.2 39.6 31.5 18.6 18.6 35.5 43.8 35.7 44.1 4.1 6.2 11 9.8 18.3 9.8 1.2 0 2.5-.1 3.8-.3 8.6-1.5 15.4-7.9 17.5-16.3.1-.2 8.8-24.3 54.7-72.7 37-39.1 61.7-51.5 70.3-54.9h.3s.3-.1.8-.4c1.5-.6 2.3-.8 2.3-.8-.4.1-.6.1-.6.1v-.1c4-1.7 11.4-4.9 11.5-5 11.1-4.8 14.8-16.8 10.4-28z"
      />
    </svg>
  );
}
