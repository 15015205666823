import { captureException } from '~/utils/exception-tracking';

export const createHttpClient = ({
  baseUrl,
  fetch = window.fetch,
  requestTransformers = [],
  errorListeners = [],
}) => {
  const sendRequest = async (url, method, config = { credentials: 'same-origin' }, payload) => {
    const response = {};
    let request = {
      url,
      method,
      credentials: config.credentials,
      headers: {
        ...config.headers,
      },
    };

    if (config && config.body) {
      request.body = config.body;
    } else if (payload) {
      request.body = JSON.stringify(payload);
      request.headers['Content-Type'] = 'application/json';
    }

    request = await requestTransformers.reduce((promises, fn) => {
      return promises.then((req) => fn(req));
    }, Promise.resolve(request));

    response.request = request;

    const res = await fetch(response.request.url, response.request);

    response.status = res.status;
    response.headers = res.headers;
    response.url = res.url;

    const data = await res.text();
    if (data) {
      response.data = data; // in case JSON.parse blows up we'll still have the response text
      try {
        response.data = JSON.parse(data);
      } catch (err) { }
    }

    if (!res.ok) {
      throw response;
    } else {
      return response.data;
    }
  };

  const requestWithErrorListener = async (url, method, config, payload) => {
    try {
      return await sendRequest(url, method, config, payload);
    } catch (errorResponse) {
      // If an actual Error was thrown instead of an HttpClientResponse
      // then we don't want our errorListeners to intercept it and our
      // Sentry error reporting should get a different payload
      if (errorResponse instanceof Error) {
        captureHttpException(errorResponse, url, method);
        throw errorResponse;
      } else {
        errorListeners.forEach((fn) => fn(errorResponse));
        captureHttpError(errorResponse);
        throw errorResponse.data;
      }
    }
  };

  return {
    requestTransformers,
    errorListeners,

    get(path, config) {
      return requestWithErrorListener(`${baseUrl}${path}`, 'GET', config);
    },

    post(path, body, config) {
      return requestWithErrorListener(
        `${baseUrl}${path}`,
        'POST',
        config,
        body
      );
    },

    put(path, body, config) {
      return requestWithErrorListener(`${baseUrl}${path}`, 'PUT', config, body);
    },

    patch(path, body, config) {
      return requestWithErrorListener(
        `${baseUrl}${path}`,
        'PATCH',
        config,
        body
      );
    },

    delete(path, config) {
      return requestWithErrorListener(`${baseUrl}${path}`, 'DELETE', config);
    },
  };
};

/**
 * Send network exceptions to Sentry
 */
function captureHttpException(err, url, method) {
  const requestUrl = new URL(url);

  captureException(err, {
    tags: {
      requestHost: requestUrl.host,
      requestPath: toReadableUrl(requestUrl.pathname),
      requestMethod: method,
    },
    extras: {
      requestUrl: url,
      requestMethod: method,
    },
    fingerprints: ['{{ default }}', toReadableUrl(url), method],
    level: 'error',
  });
}

/**
 * Send non-200 responses to Sentry as an HttpError
 */
function captureHttpError(response) {
  const requestUrl = new URL(response.url);

  captureException(new HttpError(response), {
    tags: {
      apiError: response.status >= 500 ? '5xx' : '4xx',
      requestHost: requestUrl.host,
      requestPath: toReadableUrl(requestUrl.pathname),
      requestMethod: response.request.method,
      responseStatus: response.status,
    },
    extras: {
      responseData: response.data,
      responseStatus: response.status,
      requestUrl: response.url,
    },
    fingerprints: [
      toReadableUrl(requestUrl.pathname),
      response.request.method,
      response.status,
      '{{ default }}',
    ],
    level: response.status >= 500 ? 'error' : 'info',
  });
}

/**
 * HttpError is a custom Error used to clean up the exception capturing in Sentry
 */
export class HttpError extends Error {
  constructor(response) {
    const path = new URL(response.url).pathname;
    const message = `API ${response.status}: ${response.request.method
      } - ${toReadableUrl(path)}`;

    super(message);
    this.name = 'HttpError';
  }

  toString() {
    return `HttpError: ${this.message}`;
  }
}

const UUID_REGEX = new RegExp(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/);
const ENCODED_EMAIL_REGEX = new RegExp(/.+%40.+\..+/);

/**
 * Replace UUID strings with ':uuid'
 * and encoded email strings with `:email`
 */
function toReadableUrl(url) {
  return url
    .split('/')
    .map((part) => {
      return part
        .replace(UUID_REGEX, ':uuid')
        .replace(ENCODED_EMAIL_REGEX, ':email');
    })
    .join('/');
}
