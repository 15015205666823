const chatApi = (http, modelEndpoint) => {
  if (!modelEndpoint) {
    throw new Error('chatApi: modelEndpoint is required');
  }

  return {
    sendMessage: ({ message, history, jsonData }) => {
      return http.post(`/api/labs/prompt-gpt-drf/${modelEndpoint}/`, {
        content: message.content,
        role: message.role,
        uuid: message.uuid,
        previousMessage: history[history.length - 1]?.uuid,
        createdAt: message.timeSent,
        customerUuid: message.userUuid,
        jsonData,
      });
    },
  };
};

export const lawnProblemChatApi = (http) => chatApi(http, 'lawn-problem');
export const alwaysBeClosingChatApi = (http) =>
  chatApi(http, 'always-be-closing');
