import { ShipmentStatus } from '~/features/shipments/shipment';
import { captureException } from '~/utils/exception-tracking';
import { unique } from '~/utils/functions';
import { toSentenceCase } from '~/utils/strings';

/**
 * Subscription status enum as defined by the API
 */
export const SubscriptionStatus = {
  SCHEDULED: 'sch', // Will be reauthorized on the scheduled date and transition to ACTIVE
  ACTIVE: 'act',
  CANCELLED: 'can',
  AUTH_FAIL: 'aut', // The attempt to charge the user failed
  COMPLETED: 'com',
  DO_NOT_RENEW: 'dnr', // The subscription is active but will not be reauthorized
};

/**
 * Convert an API subscription status enum to a display value
 */
export const subscriptionStatusDisplay = (status) => {
  switch (status) {
    case SubscriptionStatus.SCHEDULED:
      return 'Scheduled';
    case SubscriptionStatus.ACTIVE:
      return 'Active';
    case SubscriptionStatus.DO_NOT_RENEW:
      return 'Will Not Renew';
    case SubscriptionStatus.CANCELLED:
      return 'Cancelled';
    case SubscriptionStatus.AUTH_FAIL:
      return 'Error';
    case SubscriptionStatus.COMPLETED:
      return 'Completed';
    default:
      return toSentenceCase(status);
  }
};

/**
 * Subscription type as defined by the API
 */
export const subscriptionType = {
  LAWN_PLAN: 'law',
  SEED: 'seed', // To handle seed care actions
  MOSQUITO: 'mos',
  TOTAL_HOME: 'bar',
  TICK: 'tic',
  PEST: 'pes',
  SPRING: 'spr',
  BASIC: 'bas', // Any add-on product subscription
  VEGETABLE: 'veg',
  FLOWER: 'flo',
  TREE_LANDSCAPE: 'tal',
  VEGETABLE_FLOWER: 'vaf',
};

/**
 * Subscription name as defined by the API
 */
export const subscriptionName = {
  LAWN: 'Lawn Subscription',
  PEST: 'Pest Subscription',
  MOSQUITO: 'Mosquito Protection Plan',
  TICK: 'Tick Protection Plan',
  TOTAL_HOME: 'Total Home Protection Plan',
};

/**
 * Convert an API subscription type enum to a display value
 */
export const subscriptionTypeDisplay = (type) => {
  switch (type) {
    case subscriptionType.LAWN_PLAN:
      return 'Lawn';
    case subscriptionType.SEED:
      return 'Seed';
    case subscriptionType.PEST:
      return 'Pest';
    case subscriptionType.MOSQUITO:
      return 'Mosquito';
    case subscriptionType.TICK:
      return 'Tick';
    case subscriptionType.TOTAL_HOME:
      return 'Total Home';
    case subscriptionType.BASIC:
      return 'Add-on';
    default:
      return type;
  }
};

/**
 * Given a subscription summary determine a "name" for it
 * based on its type and item(s)
 */
export const summaryItemDisplay = (summary) => {
  if (!summary) {
    return null;
  }

  return summary.name;
};

export const shipmentItemsForSummary = (summary) => {
  // TODO: This logic assumes that different shipments have different items,
  // so if two shipments have identical items, they display as separate items and quantity is NOT incremented
  const subscriptionItemUuids = summary.subscriptionItems.map((si) => si.uuid);
  return (
    summary?.shipments
      .reduce((acc, shipment) => {
        const subscriptionShipmentItems = shipment.shipmentItems?.filter(
          (item) => subscriptionItemUuids.includes(item.subscriptionUuid)
        );

        acc.push(...subscriptionShipmentItems);

        return acc;
      }, [])
      .filter(Boolean) || []
  );
};

/**
 * Transforms a list of Subscriptions into a list of Subscription Summaries
 * that are a little easier to work with on a year basis. Each Summary
 * corresponds to a single Subscription for a particular year and includes:
 * its own SubscriptionItems list, status, cancelAt, purchaseDate, shipments, and year
 */
export function subscriptionsToSummaries(subscriptions, shipments) {
  if (
    !subscriptions ||
    !shipments ||
    subscriptions.length === 0 ||
    shipments.length === 0
  ) {
    return [];
  }

  return subscriptions
    .map((subscription) => {
      const subscriptionItemYears = subscription.subscriptionItems
        .map((subItem) => subItem.year)
        .filter(Boolean);

      const dedupedYears = [...new Set(subscriptionItemYears)].sort();

      const subscriptionItemsByYear = subscription.subscriptionItems.reduce(
        (acc, curr) => {
          if (acc[curr.year]) {
            return {
              ...acc,
              [curr.year]: [...acc[curr.year], curr],
            };
          }

          return {
            ...acc,
            [curr.year]: [curr],
          };
        },
        {}
      );

      return dedupedYears.map((year) => {
        const subscriptionItems = subscriptionItemsByYear[year];
        const status = deriveSubscriptionStatus(subscription, year);

        // CANCELLED subscription items can't be trusted when doing
        // anything other than determining a parent subscription's
        // cancellation date
        const uncancelledSubscriptionItems = subscriptionItems.filter(
          (item) => item.status !== SubscriptionStatus.CANCELLED
        );

        return {
          ...subscription,
          status,
          year,
          cancelAt:
            status === SubscriptionStatus.CANCELLED
              ? mostRecentDate(subscriptionItems.map((item) => item.cancelAt))
              : null,
          subscriptionItems: uncancelledSubscriptionItems,
          purchaseDate: mostRecentDate(
            uncancelledSubscriptionItems.map((item) => item.purchaseDate)
          ),
          activeShipmentItems:
            subscription?.activeShipmentItems?.filter(
              (shipmentItem) =>
                new Date(shipmentItem.processDate).getFullYear() === year
            ) ?? [],
          shipments: shipments
            .filter((shipment) => shipment.status !== ShipmentStatus.CANCELLED)
            .filter((shipment) =>
              shipment.shipmentItems.some((shipmentItem) =>
                uncancelledSubscriptionItems
                  .map((subItem) => subItem.uuid)
                  .includes(shipmentItem.subscriptionUuid)
              )
            ),
        };
      });
    })
    .flat();
}

/**
 * A subscription's `status` is determined by its children
 * subscription items. The subscription items for the given
 * year should either:
 * - All have the same status,
 * - Be a mix of CANCELLED + one other status
 *
 * The mix of statuses can happen when a "basic"
 * subscription item is cancelled but the lawn plan isn't.
 * In that scenario the "basic" item is set to CANCELLED
 * (even though it hasn't been paid for), but the lawn plan
 * is still SCHEDULED. The overall subscription
 * should be considered as whatever the non-CANCELLED
 * status is, which would be SCHEDULED in that scenario.
 *
 * I'm sorry.
 */
function deriveSubscriptionStatus(subscription, year) {
  if (!subscription?.subscriptionItems) {
    return null;
  }

  const uniqueStatuses = unique(
    subscription.subscriptionItems
      .filter((item) => item.year === year)
      .map((item) => item.status)
  );

  if (uniqueStatuses.length === 1) {
    return uniqueStatuses[0];
  }

  // If there are multiple statuses then it *should* only
  // be CANCELLED + *something else* and our unique array should
  // only have two items. If it doesn't then that's a data
  // integrity problem that we're not going to account for.
  // We will track it in Sentry though.
  if (uniqueStatuses.length > 2) {
    captureException(
      new Error('More than 2 unique statuses found for a subscription'),
      {
        extras: {
          subscriptionUuid: subscription.uuid,
          uniqueStatuses,
        },
        fingerprints: ['invalidSubscriptionStatuses'],
      }
    );
  }

  return uniqueStatuses.find(
    (status) => status !== SubscriptionStatus.CANCELLED
  );
}

function mostRecentDate(dates = []) {
  const byDateDesc = (a, b) => {
    return new Date(a) > new Date(b) ? -1 : 1;
  };

  return [...dates].sort(byDateDesc)[0];
}
