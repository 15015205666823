import { SOIL_TEST_DESCRIPTIONS } from '~/features/lawns/lawn';
import { toSentenceCase } from '~/utils/strings';

export const lawnsApi = (http) => {
  return {
    all: async () => {
      return http.get('/api/lawns/');
    },

    find: async (lawnUuid) => {
      return http.get(`/api/lawns/${lawnUuid}/`);
    },

    create: async ({
      line1,
      line2,
      city,
      stateCode,
      postalCode,
      countryCode,
      latitude,
      longitude,
    }) => {
      return http.post('/api/lawns/', {
        line1,
        line2,
        city,
        stateCode,
        postalCode,
        countryCode,
        latitude,
        longitude,
      });
    },

    update: async (lawnUuid, lawn) => {
      return http.put(`/api/v2/lawns/${lawnUuid}/`, lawn);
    },

    fetchSoilTest: async () => {
      const response = await http.get(`/api/soil_test_results/`);
      return parseSoilTest(response);
    },

    fetchWeatherData: async (lawnUuid) => {
      return await http.get(`/api/lawns/${lawnUuid}/weather_data/`);
    },

    fetchLawnActions: async (lawnUuid) => {
      return await http.get(`/api/lawns/${lawnUuid}/dashboard_actions/`);
    },

    // If `paymentType` is undefined, will return flexpay bundles
    fetchBundles: async ({ lawnUuid, payment_type, vwo_test }) => {
      return await http.post(`/api/v2/lawns/${lawnUuid}/lawn_bundles/`, {
        payment_type,
        vwo_test,
      });
    },

    fetchDigitalSoilTest: async (lawnUuid) => {
      return await http.get(
        `/api/prediction/lawn/${lawnUuid}/soil_properties/`
      );
    },
  };
};

export function parseSoilTest(res) {
  if (res.length === 0) {
    return null;
  }
  const results = res.map((result) => {
    const parseResult = (dataName, showRating = true) => {
      const rating =
        result[dataName]?.rating_display || result[dataName]?.rating;
      return {
        value: result[dataName]?.value,
        rating: showRating && rating,
        color: showRating && result[dataName]?.color,
        displayName: toSentenceCase(result[dataName]?.display_name),
        barLength: result[dataName]?.bar_length,
        barText: result[dataName]?.bar_value,
        description: SOIL_TEST_DESCRIPTIONS[dataName],
        dataName,
      };
    };

    const parsed = {
      reportDate: result.soil_test_date,
      conditions: result.conditions,
      data: {
        'Soil fertility': [
          parseResult('organic_matter'),
          parseResult('soil_ph'),
          parseResult('cation_exchange'),
        ],
        Macronutrients: [parseResult('phosphorus'), parseResult('potassium')],
        Micronutrients: [
          parseResult('sulfur'),
          parseResult('calcium'),
          parseResult('magnesium'),
          parseResult('boron', false),
          parseResult('iron', false),
          parseResult('manganese', false),
          parseResult('copper', false),
          parseResult('zinc', false),
        ],
        'Soil salts': [parseResult('sodium', false)],
      },
      reportCard: {
        'Fertility grade': {
          grade: result.fertility_grade,
          data: [
            parseResult('organic_matter'),
            parseResult('soil_ph'),
            parseResult('cation_exchange'),
            parseResult('nitrogen_release'),
          ],
        },
        'Nutrient grade': {
          grade: result.nutrient_grade,
          data: [
            { ...parseResult('macronutrients'), displayName: 'Macronutrients' },
            { ...parseResult('micronutrients'), displayName: 'Micronutrients' },
          ],
        },
        'Soil salt grade': {
          grade: result.soil_salt_grade,
          data: [parseResult('sodium')],
        },
      },
    };
    return parsed;
  });

  return results;
}
