export const usersApi = (http) => {
  return {
    find: async () => {
      const res = await http.get('/api/v2/users/');

      const {
        id,
        uuid,
        allAddresses,
        memberSince,
        firstName,
        lastName,
        lawnSubscriptionStatus,
        passwordCreated,
        userSlug,
        customerType,
        hasUnpaidOrder,
        email,
      } = res;

      return {
        id,
        uuid,
        email,
        addresses: allAddresses || [],
        shippingAddress: allAddresses.find((address) => address.isDefault),
        memberSince,
        firstName,
        lastName,
        lawnSubscriptionStatus,
        passwordCreated,
        userSlug,
        customerType,
        hasUnpaidOrder,
      };
    },

    setPrivacyData: (settings) => {
      return http.post('/api/set_privacy_data/', {
        caResident: settings.caResident,
        doNotSellPersonalInfo: settings.doNotSell,
      });
    },

    update: (email, newUserInfo) => {
      return http.patch(
        `/api/users/${encodeURIComponent(email)}/`,
        newUserInfo
      );
    },
  };
};
