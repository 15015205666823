import { useMemo } from 'react';

import { useProductData } from '~/features/products/products-actions';
import { captureException } from '~/utils/exception-tracking';

/**
 * This hook should be used to determine the correct review SKU
 * to use in the case where we are displaying a product that is
 * essentially a multi-pack of another product.
 *
 * ex:
 *  Mosquito Deleto skuId: MD
 *  Mosquito Deleto (2-pack) skuId: MD2
 *
 * const reviewProductSku = useReviewProductSku(MD2);
 * reviewProductSku === MD
 */
export const useReviewProductSku = (productSku) => {
  const { products } = useProductData();

  const originalProduct = useMemo(
    () =>
      products?.find((product) => product.productDetails.sku === productSku),
    [products, productSku]
  );

  if (!productSku) {
    return null;
  }

  // If a product has a reviewSkuOverride, we want to show the reviews
  // for the product with that SKU
  const reviewSkuOverride = originalProduct?.productDetails?.reviewSkuOverride;
  if (reviewSkuOverride) {
    return reviewSkuOverride;
  }

  // If a product is a kit of multiple products, we want to show the
  // reviews for the individual product that makes up the kit.
  const kittedProducts = originalProduct?.productDetails?.kittedProducts || [];
  if (kittedProducts.length === 1) {
    const singleProduct = products?.find(
      (product) => product.productDetails?.id === kittedProducts[0].id
    );

    if (!singleProduct) {
      captureException(
        new Error(
          `Reviews - Could not find single product in ${productSku} kit`
        ),
        {
          extras: {
            productSku,
            productName: originalProduct?.productDetails?.name,
          },
        }
      );

      return originalProduct.productDetails.sku;
    }

    return singleProduct?.productDetails.sku;
  }

  const originalProductSku = originalProduct?.productDetails?.sku;

  if (!originalProductSku) {
    captureException(
      new Error('Reviews - Could not find original product sku'),
      {
        extras: {
          productSku,
        },
        level: 'info',
        fingerprints: ['reviews'],
      }
    );
  }

  return productSku;
};
