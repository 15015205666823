import { toSentenceCase } from '~/utils/strings';

export const ShipmentStatus = {
  BACKORDER: 'backorder', // Sent to shipping provider, but on backorder due to out of stock
  // ** This is intentionally UPPERCASE to match subsoil **
  CANCELLED: 'CANCELLED', // Happens when shipments don't transmit due to out of stock issues
  DELIVERED: 'delivered', // Sent to final address
  ERROR: 'error', // Shipment in any error status prior to successful transmit
  EXCEPTION: 'exception', // Shipment with a delivery exception after successful transmit
  FAILED: 'failed', // Shipment that has a permanent delivery failure for which we should not update
  HOLD: 'hold',
  NO_PAYMENT: 'no_payment', // Items not purchased yet
  OPEN: 'open', // Created in our DB, should not be transmitted to shipping provider
  PACKED: 'packed', // In process, can't be cancelled, updated, etc.
  PENDING: 'pending', // Not sent to shipping provider
  PRESUMED_DELIVERED: 'presumed_delivered',
  PROCESSING: 'processing', // In process, can be cancelled, updated, etc.
  SHIPPED: 'shipped', // Left shipping provider
  TRANSMITTED: 'transmitted', // Sent to shipping provider, not in process
  UNDELIVERED: 'undelivered',
  UNPAID: 'unpaid', // FlexPay - shipment is unpaid and will be charged & shipped at a later date
};

// TODO: Get better status details from Chris
export const TrackingStatus = {
  PENDING: 'pending',
  SHIPPED: 'shipped', // Left shipping provider
  DELIVERED: 'delivered', // Arrived at final address
  ERROR: 'error',
  EXCEPTION: 'exception',
  UNKNOWN_EMPTY_STRING: '', // No tracking data available - shipping provider may or may not have order
};

/**
 * Convert a shipment status into a display value for users
 */
export const shipmentStatusDisplay = (status = '') => {
  switch (status) {
    case TrackingStatus.PENDING:
    case TrackingStatus.UNKNOWN_EMPTY_STRING:
    case ShipmentStatus.UNPAID:
    case ShipmentStatus.PENDING:
    case ShipmentStatus.PACKED:
      return 'Scheduled';
    case ShipmentStatus.TRANSMITTED:
    case ShipmentStatus.PROCESSING:
      return 'Processing';
    case ShipmentStatus.EXCEPTION:
    case ShipmentStatus.FAILED:
      return 'Error';
    case ShipmentStatus.ERROR:
      return 'Delayed';
    case ShipmentStatus.PRESUMED_DELIVERED:
      return 'Delivered';
    default:
      return toSentenceCase(status);
  }
};

/**
 * Determine if a given shipment is allowed to be
 * shown to a user based on its status
 */
export const isUserFacingShipment = (shipment) => {
  if (!shipment) {
    return false;
  }

  // We hide the following statuses:
  return ![
    ShipmentStatus.OPEN,
    ShipmentStatus.CANCELLED,
    ShipmentStatus.EXCEPTION,
    ShipmentStatus.FAILED,
  ].includes(shipment.status);
};

export const isPreShipmentStatus = (status) => {
  return [
    ShipmentStatus.ERROR,
    ShipmentStatus.PENDING,
    ShipmentStatus.TRANSMITTED,
    ShipmentStatus.PROCESSING,
    ShipmentStatus.PACKED,
    ShipmentStatus.UNPAID,
    TrackingStatus.PENDING,
    TrackingStatus.UNKNOWN_EMPTY_STRING,
  ].includes(status);
};

const ERROR_SHIPMENT_DATE_PADDING_DAYS = 10;

/**
 * Determine the date to show for a given shipment.
 *
 * Priority:
 * - shippedAtDate is the day the shipment left the warehouse
 * - transmittedAtDate is the day the shipment was actually sent to the 3PL
 * - estimatedShipmentDate adds an optional shipment window buffer to the target date
 * - targetShipmentDate is the day we plan to transmit to the 3PL
 *
 * If the shipment has an error status pad the date by ERROR_SHIPMENT_DATE_PADDING_DAYS.
 * Note that this error padding only happens in topsoil.
 *
 * @returns {string | null} - ISO date string
 */
export const derivedShipmentDate = (shipment) => {
  if (!shipment) {
    return null;
  }

  if (shipment.shippedAtDate) {
    return shipment.shippedAtDate;
  }

  if (shipment.transmittedAtDate) {
    return shipment.transmittedAtDate;
  }

  const estimatedOrTargetDate =
    shipment.estimatedShipmentDate ||
    shipment.targetShipmentDate ||
    shipment.processDate;

  if (shipment.status === ShipmentStatus.ERROR) {
    const date = new Date(estimatedOrTargetDate);
    date.setDate(date.getDate() + ERROR_SHIPMENT_DATE_PADDING_DAYS);
    return date.toISOString();
  }

  return estimatedOrTargetDate;
};
