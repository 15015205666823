import React, { useState } from 'react';

import Button from '~/components/button';
import InputField from '~/components/inputs/input-field';
import PasswordField from '../../inputs/password-field';
import { Form, Field } from '~/components/form';
import { valid, required, email } from '~/utils/validation';
import { forgotPasswordRoute } from '~/routes';
import { FACEBOOK_SIGN_IN_FLAG, GOOGLE_SIGN_IN_FLAG } from '~/feature-flags';
import GoogleSignInButton, {
  GOOGLE_BUTTON_TEXT_OPTIONS,
  SignInButtonLoadingUI,
} from '~/components/google-sign-in-button';
import { useSession } from '~/features/session/session-actions';
import {
  getValidationErrorMessageFor,
  isIncorrectEmailOrPasswordError,
  isValidationError,
} from '~/api/api-errors';
import { isFacebookBrowser } from '~/utils/is-facebook-browser';
import FacebookSignInButton from '~/components/facebook-sign-in-button';

import styles from '~/components/sessions/sessions.module.scss';

const SignInForm = ({ onSuccess }) => {
  const [isSigningInWithGoogle, setIsSigningInWithGoogle] = useState(false);
  const [isSigningInWithFacebook, setIsSigningInWithFacebook] = useState(false);
  const { signIn } = useSession();

  const handleSubmit = async (formValues) => {
    try {
      await signIn({
        email: formValues.email.toLowerCase(),
        password: formValues.password,
      });

      onSuccess();
    } catch (err) {
      switch (true) {
        case isIncorrectEmailOrPasswordError(err):
          return {
            email: 'Incorrect email or password',
            password: 'Incorrect email or password',
          };
        case isValidationError(err):
          return {
            email: getValidationErrorMessageFor(err, 'email'),
            password: getValidationErrorMessageFor(err, 'password'),
          };
        default:
          return {
            email: 'Something went wrong. Please try again.',
            password: 'Something went wrong. Please try again.',
          };
      }
    }
  };

  return (
    <>
      {FACEBOOK_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithFacebook ? (
            <FacebookSignInButton
              onSuccess={onSuccess}
              onSignInStart={() => setIsSigningInWithFacebook(true)}
              onError={() => setIsSigningInWithFacebook(false)}
            />
          ) : (
            <SignInButtonLoadingUI style={{ marginBottom: '2rem' }} />
          )}
        </>
      )}

      {GOOGLE_SIGN_IN_FLAG && !isFacebookBrowser && (
        <>
          {!isSigningInWithGoogle ? (
            <GoogleSignInButton
              text={GOOGLE_BUTTON_TEXT_OPTIONS.CONTINUE}
              onSignInStart={() => setIsSigningInWithGoogle(true)}
              onSuccess={onSuccess}
            />
          ) : (
            <SignInButtonLoadingUI />
          )}
          <Divider />
        </>
      )}

      <Form onSubmit={handleSubmit} validate={validate}>
        {({ isSubmitting }) => (
          <>
            <Field
              label="Email address"
              name="email"
              type="email"
              component={InputField}
              autoComplete="email"
            />

            <Field
              label="Password"
              name="password"
              type="password"
              component={PasswordField}
              autoComplete="current-password"
            />

            {/* TODO: is there a way to redirect the user back to the Pest Quiz after they reset their password? */}
            <p className={styles.forgotPassword}>
              Forgot your password?{' '}
              <Button
                className={styles.link}
                variant="link"
                to={forgotPasswordRoute()}
              >
                Request a sign in link.
              </Button>
            </p>

            <Button
              type="submit"
              isLoading={isSubmitting}
              fullWidth
              disabled={isSigningInWithGoogle}
            >
              Sign in
            </Button>
          </>
        )}
      </Form>
    </>
  );
};

function validate(formValues) {
  return {
    email: valid(required(), email())(formValues.email),
    password: valid(required())(formValues.password),
  };
}

export default SignInForm;

// TODO: need a better name
export const Divider = ({
  text = 'or',
  backgroundColor = 'var(--off-white-color)',
}) => {
  return (
    <div className={styles.divider}>
      <hr className={styles.rule} />
      <span style={{ backgroundColor }}>{text}</span>
    </div>
  );
};
