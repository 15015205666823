import React, { useEffect, useState } from 'react';

import LoadingIndicator from '~/components/loading-indicator';
import { useScript } from '~/hooks/use-script';
import { captureException } from '~/utils/exception-tracking';

import styles from '~/components/products/yotpo-product-reviews-widget.module.scss';

export const widgetTypes = {
  MAIN: 'yotpo-main-widget',
  STARS: 'bottomLine',
};

const YOTPO_API_KEY = window.env.YOTPO_API_KEY;
const YOTPO_WIDGET_SRC = `https://staticw2.yotpo.com/${YOTPO_API_KEY}/widget.js`;

/**
 * Render the Yotpo reviews widget, either with text reviews or only the "stars"
 * https://support.yotpo.com/en/article/generic-other-platforms-installing-yotpo-v3
 *
 * Only productId and widget are required props, the rest are optional
 */
const YotpoProductReviewsWidget = ({
  productId,
  productName,
  productPageUrl,
  productImageUrl,
  productPrice,
  widget,
  children,
}) => {
  useYotpoWidget(productId);

  if (!productId || !widget) {
    return null;
  }

  return (
    <div className={styles.yotpoProductReviewsWidget}>
      <div
        className={`yotpo ${widget}`}
        data-product-id={productId}
        data-name={productName}
        data-url={productPageUrl}
        data-image-url={productImageUrl}
        data-price={productPrice}
        data-currency="USD"
      >
        {children ? (
          children
        ) : (
          <div style={{ textAlign: 'center' }}>
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
};

export default YotpoProductReviewsWidget;

function useYotpoWidget(productId) {
  const [isLoaded, hasError] = useScript(YOTPO_WIDGET_SRC);
  const [yotpo, set] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      set(window.yotpo);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (hasError) {
      captureException(new Error('Yotpo widgets failed to load'), {
        level: 'info',
      });
    }
  }, [hasError]);

  // https://support.yotpo.com/en/article/loading-yotpo-with-ajax
  useEffect(() => {
    if (!yotpo || !productId) {
      return;
    }

    // This bit was removed from their docs, but still seems necessary
    if (!yotpo.initialized) {
      return;
    }

    // Yes, Yotpo now puts 2 global variables on the window and named them `yotpo` and `Yotpo`.
    // Way to go 👏
    try {
      const yotpoApi = new window.Yotpo.API(yotpo);
      yotpoApi.refreshWidgets();
    } catch (err) {
      captureException(new Error('Yotpo sucks'), {
        extras: {
          error: err,
        },
      });
    }
  }, [yotpo, productId]);

  return [yotpo, hasError];
}
