import React, { Component } from 'react';

import GeneralErrorContent from '~/components/error/general-error-content';
import { captureException } from '~/utils/exception-tracking';

import styles from '~/components/error/error.module.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err, errorInfo) {
    captureException(err, {
      extras: {
        location: 'errorBoundary',
        ...errorInfo,
      },
      tags: {
        errorBoundary: true,
      },
    });
  }

  render() {
    if (this.state.hasError === null) {
      return this.props.children;
    }

    return (
      <main className={styles.errorBody}>
        <GeneralErrorContent />
      </main>
    );
  }
}

export default ErrorBoundary;
