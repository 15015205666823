export const dynamicFormsApi = (http) => {
  return {
    submit: async (form, answers, experiment) => {
      const payload = {
        answers: serializeAnswers(form, answers),
        metaData: serializeMetadata(form, experiment),
        externalSourceIdentifier: form.slug.current,
        externalSourceCategory: form.category,
      };

      return http.post('/api/forms/', payload);
    },

    fetchAnswers: async () => {
      return http.get('/api/forms/');
    },
  };
};

function serializeAnswers(form, answers) {
  const serialized = [];
  for (const fieldSlug in answers) {
    const field = form.fields.find((field) => field.slug.current === fieldSlug);
    serialized.push({
      question: fieldSlug,
      type: field?.type || 'MANUAL', // Manual => value was added to payload programmatically
      response: answers[fieldSlug],
    });
  }
  return serialized;
}

function serializeMetadata(form, experiment) {
  return {
    category: form.category,
    description: form.description,
    experiment: serializeExperiment(experiment),
    fields: serializeFields(form.fields),
    name: form.name,
    slug: form.slug.current,
  };
}

function serializeFields(fields) {
  return fields.map((field) => {
    return {
      choices: serializeChoices(field.choices),
      label: field.label,
      pageNumber: field.pageNumber,
      required: false,
      slug: field.slug.current,
      type: field.type,
    };
  });
}

function serializeChoices(choices) {
  return choices?.map((choice) => {
    return {
      label: choice.label,
      slug: choice.slug.current,
    };
  });
}

function serializeExperiment(experiment) {
  if (!experiment) {
    return;
  }

  const variants = experiment.variants.map((variant) => {
    return {
      name: variant.name,
      weight: variant.weight,
      formSlug: variant.form.slug.current,
    };
  });

  return {
    active: experiment.active,
    controlVariant: {
      name: experiment.controlFormVariantName,
      weight: experiment.controlFormVariantWeight,
      formSlug: experiment.controlFormSlug,
    },
    name: experiment.name,
    variants,
  };
}
