import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { atom, useAtom, useStore } from 'jotai';

import { api } from '~/api';
import { sendUTMData } from '~/utils/utm-tracking';
import {
  getSavedSession,
  saveSession,
  deleteSession,
} from '~/features/session/session';
import {
  analyticsBeacon,
  DeprecatedEventType,
  EventType,
} from '~/utils/analytics';
import {
  captureException,
  setExceptionTrackingUser,
} from '~/utils/exception-tracking';
import { sendPrivacySettings } from '~/features/privacy-settings';

const initializingAtom = atom(true);
export const sessionAtom = atom(null);

export const SIGN_IN_PROVIDER = {
  EMAIL_PASSWORD: 'email-password',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

export const useSession = () => {
  const store = useStore();
  const queryClient = useQueryClient();
  const [isInitializing, setIsInitializing] = useAtom(initializingAtom);
  const [, setSession] = useAtom(sessionAtom);

  // Instead of reading from the session returned from useAtom
  // we intentionally read directly from the store. This small
  // but important optimization is necessary for some parts of
  // the app to work correctly, mostly surrounding sign in/sign out.
  const user = store.get(sessionAtom)?.user;
  const isSignedIn = Boolean(user);

  const createSession = useCallback(async () => {
    const user = await api.users.find();
    setSession((prev) => ({
      ...prev,
      user,
    }));

    setExceptionTrackingUser({ email: user.email });
    analyticsBeacon.emit(EventType.SESSION_CREATED, {
      email: user.email,
      userUuid: user.uuid,
      lawnSubscriptionStatus: user.lawnSubscriptionStatus,
    });
    analyticsBeacon.emit(
      DeprecatedEventType.SET_USER,
      user.id,
      user.email,
      user.uuid
    );

    // No need to wait for these to return,
    // so we just fire and forget. Since we don't `await`
    // them we have to catch their errors explicitly
    sendUTMData().catch(() => {});
    sendPrivacySettings().catch(() => {});
  }, [setSession]);

  const signIn = useCallback(
    async (
      { email, password },
      isPostRegistration = false,
      provider = SIGN_IN_PROVIDER.EMAIL_PASSWORD
    ) => {
      const authRes = await api.sessions.signIn({ email, password });
      saveSession({
        token: authRes.token,
        user: {
          email,
        },
      });

      await createSession(email);
      queryClient.removeQueries(['sundayStoreSubsoil']);

      if (!isPostRegistration) {
        analyticsBeacon.emit(EventType.USER_SIGN_IN, {
          email,
          userUuid: authRes.uuid,
          provider,
        });
        analyticsBeacon.emit(DeprecatedEventType.SIGN_IN, email);
      }
    },
    [createSession, queryClient]
  );

  const register = useCallback(
    async ({ creationMethod, email, password, source }) => {
      const user = await api.sessions.register({ email, password, source });

      analyticsBeacon.emit(DeprecatedEventType.REGISTER);
      analyticsBeacon.emit(EventType.ACCOUNT_CREATED, {
        email,
        source: source,
        userSlug: user.user_slug,
        userUuid: user.uuid,
        provider: SIGN_IN_PROVIDER.EMAIL_PASSWORD,
      });
      password &&
        analyticsBeacon.emit(EventType.PASSWORD_CREATED, {
          creation_method: creationMethod,
          email,
          userUuid: user.uuid,
        });

      return signIn({ email, password: password || user.uuid }, true);
    },
    [signIn]
  );

  const signOut = useCallback(async () => {
    // Destroying the token via API isn't mandatory, so if it fails
    // continue signing out but capture the error in Sentry
    try {
      const session = await getSavedSession();
      if (session?.token) {
        await api.sessions.signOut(session.token);
      }
    } catch (err) {
      captureException(new SignOutError(err), {
        fingerprints: ['SignOutError'],
        level: 'info',
      });
    }

    await queryClient.removeQueries();
    setSession(null);
    await deleteSession();

    setExceptionTrackingUser(null);
    analyticsBeacon.emit(EventType.USER_SIGN_OUT);
    analyticsBeacon.emit(DeprecatedEventType.SET_USER, null, null);
  }, [queryClient, setSession]);

  const init = useCallback(async () => {
    setIsInitializing(true);
    const session = await getSavedSession();

    api.setErrorListener((response) => {
      if (response.status === 401) {
        signOut();
      }
    });

    if (session?.user) {
      try {
        await createSession(session.user.email);
      } catch (_) {
        await signOut();
      }
    }

    setIsInitializing(false);
  }, [createSession, setIsInitializing, signOut]);

  /**
   * Used to *pull* the user instead of relying on the
   * value returned from this hook. Useful to avoid stale closures
   * in functions that need to read the user in the same loop
   * that the user object could change.
   */
  const getUser = useCallback(() => {
    return store.get(sessionAtom)?.user;
  }, [store]);

  const refreshUser = useCallback(async () => {
    const user = await api.users.find();
    setSession((prev) => ({
      ...prev,
      user,
    }));
    return user;
  }, [setSession]);

  return {
    getUser,
    init,
    isInitializing,
    isSignedIn,
    refreshUser,
    register,
    signIn,
    signOut,
    user,
  };
};

class SignOutError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SignOutError';
  }
}
